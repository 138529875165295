import * as React from "react"
import Layout from "../components/layout"
import ProjectPreview from "../components/projectPreview"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentJson {
        nodes {
          projectTitle
          projectSummary
          projectAttributes
          altText
          projectYear
          projectLink
          externalLink
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                pngOptions: {compressionSpeed: 1, quality: 100}
              )
            }
          }
        }
      }
    }
    `)

  return (
    <Layout theme='light'>
      <section className={'sm:grid grid-cols-desktop sm:grid-rows-1 h-hero max-h-hero'}>
        <aside className={'font-serif mb-4 col-start-2 col-span-1 sm:p-4 sm:transform sm:rotate-180 sm:vertical-rl sm:self-start sm:justify-self-center'}>David Pierce</aside>
        <h1 className={'col-span-10 col-start-4 lg:col-start-4 lg:col-span-10  font-sans-medium text-4xl sm:text-5xl md:text-6xl lg:text-7xl leading-snug sm:leading-snug md:leading-snug lg:leading-snug sm:tracking-wide tracking-wider h-full'}>
        David is an experienced designer currently leading the product design team at <a href='https://homechef.com' target="_blank" rel="noopener noreferrer" className='display-link'>Home Chef</a>.
        </h1>
      </section>
      <section className={'grid grid-cols-desktop gap-y-24'}>
        <h2 id='work' className={'col-start-2 col-span-12 font-sans-medium text-7xl sm:text-9xl flex justify-between'}>
          <span>W</span>
          <span>O</span>
          <span>R</span>
          <span>K</span>
        </h2>
        {data.allContentJson.nodes.map((content, index) => {
          return <ProjectPreview
                    key = {index}
                    projectPreviewImage = {content.image}
                    projectTitle = {content.projectTitle}
                    projectSummary = {content.projectSummary}
                    projectAttributes = {content.projectAttributes}
                    projectPreviewAltImageText = {content.altText}
                    projectYear = {content.projectYear}
                    projectLink = {content.projectLink}
                    externalLink = {content.externalLink}
                />
              }
            )
          }
      </section>
    </Layout>
  )
}

export default IndexPage
