import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FiArrowRight, FiExternalLink } from "react-icons/fi"


const ProjectPreview = ({ projectTitle, projectSummary, projectAttributes, projectPreviewImage, projectPreviewAltImageText, projectYear, projectLink, externalLink }) => {
  const image = getImage(projectPreviewImage)
  let linkButton = projectLink ?
    <Link to={`${projectLink}`} className='transition duration-200 font-sans-book tracking-wider border-2 border-black rounded px-4 py-2 hover:bg-gray-100'>Read case study <FiArrowRight className='inline mb-1'/></Link> 
    : 
    (externalLink ?
      <a href={`${externalLink}`} target="_blank" rel="noreferrer" className='transition duration-200 font-sans-book tracking-wider border-2 border-black rounded px-4 py-2 hover:bg-gray-100'>Visit indy.gov <FiExternalLink className='inline mb-1'/></a>
    : 
    <aside className='inline cursor-wait font-sans-book tracking-wider rounded px-4 py-2 bg-gray-100'>Coming soon...</aside>)

  return (
    <article className={'col-span-full sm:col-start-3 sm:col-span-10 md:col-start-4 md:col-span-8'}>
        <GatsbyImage
          alt={projectPreviewAltImageText}
          image={image}
          className={'transition duration-300 ease-in-out rounded-lg shadow-lg group-hover:shadow-xl'}
          imgClassName={'rounded-lg'}
        />
        <div className={'mx-4 mt-6'}>
          <div className={'mb-2'}>
          <h3 className={'transition duration-200 inline font-sans-book tracking-wider group-hover:text-blue'}>{projectTitle}</h3>
            <span className={'font-serif ml-4 text-gray-600 inline'}>{projectYear}</span>
          </div>
          <p className={'mb-2 text-base tracking-wider leading-7'}>{projectSummary}</p>
          <p className={'mb-8 font-serif text-base text-gray-600'}>{projectAttributes}</p>
          {linkButton}
        </div>
    </article>
  )
}

ProjectPreview.propTypes = {
  projectTitle: PropTypes.string,
  projectSummary: PropTypes.string,
  projectAttributes: PropTypes.string,
  projectPreviewImage: PropTypes.object,
  projectPreviewAltImageText: PropTypes.string,
  projectYear: PropTypes.string,
  projectLink: PropTypes.string,
  externalLink: PropTypes.string
}

ProjectPreview.defaultProps = {
  projectTitle: 'Project Title',
  projectSummary:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis viverra magna suscipit senectus vulputate amet amet a eu. Bibendum in placerat nisl ultricies aenean venenatis pellentesque eu risus. Nec ut ante scelerisque sagittis, odio.',
  projectAttributes: 'attribute | attribute | attribute'
}

export default ProjectPreview
